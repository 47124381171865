@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

:root {
  --black: #1c1e21;
  --white: #ffffff;
  --tw-color1: #a855f7;
  --tw-color1-hover: #9333ea;
}

body {
  background: var(--black);
  font-family: "Inter", sans-serif;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.logo {
  width: max-content;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 6px;
}

.logo > svg {
  height: 24px;
}

.container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  min-width: 3rem;
  font-size: 1rem;
  background: #e5e5ea;
  background-image: linear-gradient(to left, #cc25b3 0%, #418dff 101.52%);
  color: #fff;
  width: 180px;
  text-align: center;
  border-radius: 9999px;
  border-style: none;
}

.btn2 {
  cursor: pointer;
  text-align: center;
  padding: 5px;
  color: #fff;
  font-weight: 600;
}

.input {
  width: 75%;
  background-color: transparent;
  border: 1px solid grey;
  border-radius: 8px;
  color: #fff;
  height: 48px;
  padding: 0 16px;
  font-size: 1rem;
  margin-bottom: 16px;
}

.btn:hover {
  opacity: 0.8;
}

.btn:disabled {
  opacity: 0.5;
}

.address {
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 2rem;
}
